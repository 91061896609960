import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SubpageHero from '../components/subpage-hero';

const StandardPageTemplate = (props) => {
    return (
    <Layout>
       <SEO title={props.data.contentfulStandardPage.title} />
    
        <SubpageHero title={props.data.contentfulStandardPage.title} />

        <div className="post-content">

            <div dangerouslySetInnerHTML={
                    {__html: `${props.data.contentfulStandardPage.pageContent.childMarkdownRemark.html}`}
                } />
            
        </div>

  </Layout>
)
}

export default StandardPageTemplate;


export const query = graphql`
 query StandardPageTemplate($id: String!) {

    contentfulStandardPage(id: {eq: $id}) {
        id
        slug
        title
        pageContent {
          childMarkdownRemark {
            html
          }
        }
      }
 }
`
